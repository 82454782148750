/* You can add global styles to this file, and also import other style files */

@import (less) "../node_modules/angular-calendar/css/angular-calendar.css";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Theme definition
.light-background {
  color: #000000;
  background-color: #fffab3
}

.light-warn-background {
  color: #000000;
  background-color: #f3acac
}

.light-text {
  color: #6c757d
}

.light-text:hover {
  color: #000000
}

.full-fill {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.full-width {
  width: 100%;
}

.inline {
  display: inline;
}

.item {
  padding-left: 6px !important;
  margin-bottom: 1em;
}

.item-title-icon {
  vertical-align: top;
  font-size: 1.25em;
}

.item-title-text {
  font-weight: bold;
}

.item-date {
  color: lightgrey;
  float: right;
}

.icon-cell-bottom {
  width: 24px;
}

.first-of-type {
  padding-left: 24px !important;
}

.newlines {
  white-space: pre-wrap;
}

.hoverable-container {
  display: inline;
}

.hoverable-container:hover .hoverable-content {
  opacity: 0.3;
}

.hoverable-container:hover .hoverable-action-content {
  opacity: 1;
}

.hoverable-content {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
  top: auto;
  left: inherit;
}

.hoverable-action-content {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%)
}

.mat-header-cell {
  font-weight: bold;
  font-size: larger;
  color: mat-color(purple);
}

.cursor-pointer {
  cursor: pointer;
}

// style theming
.mat-drawer-container {
  background: transparent;
}

// bugFix: in ngx-tabulator-tables -> mat-paginator background NOT transparent
gits-tabulator-table > .table-paginator > mat-paginator {
  background: transparent;
}

.table-btn {
  padding-right: 7px;
}

// mat-drawer backdrop more transparency
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, .1);
}

.mat-drawer-container {
  .mat-drawer {
    border: 1px solid #ccc;
    padding: 15px;
    min-width: 300px;

    .attached-docs-container {
      display: flex;
      flex-direction: column;
      height: 100%;

      .attached-doc-title {
        align-self: center;
      }

      .attached-doc-item {
        align-self: start;
      }

      .attached-doc-download-btn {
        display: flex;
        font-size: .9em;

        span {
          align-content: center;
        }
      }

      .attached-doc-close-btn {
        margin-top: auto;
      }
    }
  }
}


